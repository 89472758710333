<template>
    <div class="copywriter">
        <div class="padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">商品标题名称：</div>
                    <el-input v-model="param.goods_name" placeholder="请输入内容"></el-input>
                </div>
                <div class="searchdan time">
                    <div class="title">创建时间：</div>
                    <el-date-picker v-model="param.pickerTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onSearch()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <!-- <div class="ification padd20 borbut20">
            <div class="ificaTitle">文案分类：</div>
            <div class="ificaList">
                <div class="dan" :class="param.video_type == '' ? 'active' : ''" @click="toificaList('全部')">全部</div>
                <div class="dan" :class="item.id == param.video_type ? 'active' : ''" v-for="(item,key) in video_type_list" :key="key" @click="toificaList(item)">{{item.type_name}}</div>
            </div>
        </div> -->
        <div class="TableList padd20">
            <div class="duoshao">
                <div class="title">
                    消耗统计列表
                    <el-button type="primary" icon="el-icon-upload" @click="onaddAccount()">添加文案</el-button>
                </div>
                <div class="lvtotal">
                    总条数：{{lvtotal}} 条
                </div>
            </div>
            <el-table :data="addList" empty-text="暂无数据" @sort-change="onSortchange"  @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="goods_name" label="广告文案">
                    <template slot-scope="scope">
                        <el-popover placement="bottom" @show="onPopoverClick(scope.row)" width="500" trigger="click" :content="scope.row.goods_name">
                            <span class="copyInfo" slot="reference">{{scope.row.goods_name}}</span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="click_num" label="点击次数" sortable='custom' width="200"></el-table-column>
                <el-table-column prop="copy_num" label="复制次数" sortable='custom' width="200"></el-table-column>
                <el-table-column prop="created_at" label="添加时间" sortable='custom' width="200"></el-table-column>
                <el-table-column prop="video_type.type_name" label="文案分类" width="200"></el-table-column>
                <el-table-column prop="date" label="操作" width="300">
                    <template slot-scope="scope">
                        <el-button icon="el-icon-paperclip" @click="doCopy(scope.row)">复制文案</el-button>
                        <el-button type="primary" icon="el-icon-edit" @click="onaddAccount(scope.row)">修改</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="onDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @size-change="handleSizeChange" :page-sizes="[20, 40, 50, 100]" @current-change="DialogCurrentChange" background layout="sizes,prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
        </div>
        <addAccount ref="addAccount" @ongetVideoGoodsList='ongetVideoGoodsList'></addAccount>
    </div>
</template>

<script>
import addAccount from './addAccount.vue'
export default {
    data() {
        return {
            param: {
                goods_name: '',//文案标题
                pickerTime: [],//创建时间
                video_type: '',//文案分类
            },

            addList: [],//数据列表
            paramData: {},//表格参数
            per_page: 20, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1,//表格当前第几页on

            //排序
            stortingfield: '',//排序字段
            order_type: '',//排序类型
        };
    },
    components: {
        addAccount
    },

    mounted() {
        this.ongetVideoGoodsList()
    },

    methods: {
        handleSelectionChange(list){
            // console.log(list)
        },
        //排序
        onSortchange(column, prop, order) {
            column.order == 'ascending' ? column.order = 'asc' : ''
            column.order == 'descending' ? column.order = 'desc' : ''
            //排序
            this.stortingfield = column.prop,//排序字段
                this.order_type = column.order,//排序类型
                this.ongetVideoGoodsList()
        },
        //删除文案
        doCopy(msg) {
            var that = this
            this.$copyText(msg.goods_name).then(
                function (e) {
                    that.$message({ message: '复制成功', type: 'success' });
                    that.onclickCount(msg.id, 2)
                },
                function (e) {
                    that.$message({ message: '复制失败，请重试', type: 'error' });
                }
            );
        },
        //视频删除点击触发
        onDelete(item) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.ondelVideoChild(item)
            }).catch(() => {
                this.$message({ type: 'info', message: '已取消删除' });
            });
        },
        //删除视频接口执行
        ondelVideoChild(item) {
            var param = {
                goods_title_id: item.id
            }
            this.$service.post(this.$api.delVideoGoods, param, (res) => {
                if (res.code == '200') {
                    this.$message({ type: 'success', message: '删除成功!' });
                    this.ongetVideoGoodsList()
                }
            })
        },
        //点击添加或者修改文案
        onaddAccount(item) {
            this.$refs.addAccount.onDialog(item)
        },
        //点击广告文案
        onPopoverClick(item) {
            this.onclickCount(item.id)
        },
        //点击增加浏览次数
        onclickCount(id, count_type) {
            var param = {
                id: id,
                click_type: 'video_goods',
                deleteJiaZai: true,
            }
            count_type == 2 ? param.count_type = 2 : ''
            this.$service.post(this.$api.clickCount, param, (res) => {
                if (res.code == '200') { }
            })
        },
        //  //点击分类
        // toificaList(item){
        //     item == '全部' ? this.param.video_type = '' : this.param.video_type = item.id
        //     this.ongetVideoGoodsList()
        // },
        //文案分类接口
        // ongetVideoTypeList(){
        //     this.$service.get(this.$api.getVideoTypeList,{}, (res)=> {
        //         if(res.code == '200'){
        //             this.video_type_list = res.data
        //         }
        //     })
        // },
        // 放在父及页面里
        //接收搜索参数
        onAllSearch(data) {
            this.goods_name = data.goods_name,//文案标题
                this.video_type = data.video_type,//文案分类
                this.pickerTime = data.pickerTime,//日期参数
                this.onSearch()
        },

        //商品搜索
        onSearch() {
            this.paramData = {
                goods_name: this.param.goods_name,//商品标题名
                start_created_at: this.param.pickerTime ? this.param.pickerTime[0] : "", //开始日期
                end_created_at: this.param.pickerTime ? this.param.pickerTime[1] : "", //结束日期
            }
            this.paramPage = 1
            this.ongetVideoGoodsList()
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetVideoGoodsList()
        },
        //数据列表
        ongetVideoGoodsList() {
            this.addList = []
            var param = this.paramData
            param.per_page = this.per_page
            param.page = this.paramPage
            param.video_type = this.param.video_type,//文案分类
                param.field = this.stortingfield
            param.order_type = this.order_type
            this.$service.get(this.$api.getVideoGoodsList, param, (res) => {
                if (res.code == '200') {
                    this.addList = res.data.data
                    this.lvtotal = res.data.total;
                }
            })
        },
        //每页多少条触发条件
        handleSizeChange(val) {
            this.per_page = val
            this.ongetVideoGoodsList()
        },
    },



    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.ification {
    display: flex;
    .ificaTitle {
        padding-top: 10px;
        width: 80px;
        flex-shrink: 0;
    }
    .ificaList {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        .dan {
            min-width: 85px;
            text-align: center;
            padding: 10px 15px;
            cursor: pointer;
            &.active {
                background: #0075f7;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
}
</style>
<style lang="scss">
.copywriter {
    .el-table {
        .copyInfo {
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>